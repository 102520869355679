import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { connectedUser } from "../services/apis/connectionApis";

export const fetchStudentList = createAsyncThunk(
    "students/fetchStudentList",
    async (
        { page = 1, limit = 12, searchTerm, skillLevel, subcategory },
        { rejectWithValue }
    ) => {
        try {
            const queryParams = new URLSearchParams();

            queryParams.append("page", page);
            queryParams.append("limit", limit);

            if (searchTerm && searchTerm.trim() !== "") {
                queryParams.append("customFilters", searchTerm);
            }

            if (subcategory && subcategory.length > 0) {
                queryParams.append("subcategory", subcategory.join(","));
            }

            if (skillLevel && skillLevel.length > 0) {
                queryParams.append("skillLevel", skillLevel.join(","));
            }


            const response = await connectedUser({
                query: queryParams.toString(),
            });

            return {
                connections: response.data || [],
                currentPage: response.pagination.currentPage,
                totalPages: response.pagination.totalPages,
                totalUsers: response.pagination.totalUsers,
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const studentSlice = createSlice({
    name: "students",
    initialState: {
        studentList: [],
        loading: true,
        error: null,
        pagination: {
            totalPages: 1,
            currentPage: 1,
            totalUsers: 0,
        },
    },
    reducers: {
        resetStudentState: () => ({
            studentList: [],
            loading: false,
            error: null,
            pagination: {
                totalPages: 1,
                currentPage: 1,
                totalUsers: 0,
            },
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStudentList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStudentList.fulfilled, (state, action) => {
                state.loading = false;
                state.studentList = action.payload.connections;
                state.pagination = {
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    totalUsers: action.payload.totalUsers,
                };
            })
            .addCase(fetchStudentList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.studentList = [];
                state.pagination = {
                    totalPages: 1,
                    currentPage: 1,
                    totalUsers: 0,
                };
            });
    },
});


export const { resetStudentState } =
    studentSlice.actions;
export default studentSlice.reducer;