import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import useUserData from "./useUserData";
import { useOnlineUsers } from "../contexts/OnlineUserContext";

const useSocket = (onNewMessage) => {
  const { setOnlineUsers, setChatHistory, receiver, setReceiver } =
    useOnlineUsers();
  const [unreadMessages, setUnreadMessages] = useState({});
  const userData = useUserData();
  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_BACKEND_URL);
    }
    const socket = socketRef.current;

    socket.on("connect", () => {
      if (userData) {
        socket.emit("join", userData._id);
      }
    });

    const handleBeforeUnload = () => {
      if (userData && socket) {
        socket.emit("user_offline", userData._id);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    socket.on("userStatusChange", ({ userId, status, lastActive }) => {
      if (receiver && receiver._id === userId) {
        setReceiver((prevReceiver) => ({
          ...prevReceiver,
          isOnline: status === "online" ? true : false,
          lastActive: status === "offline" ? lastActive : null,
        }));
      }
      setOnlineUsers((prevUsers) => {
        const updatedUsers = new Map(prevUsers);
        if (status === "online") {
          updatedUsers.set(userId, true);
        } else {
          updatedUsers.delete(userId);
        }
        return updatedUsers;
      });
    });

    socket.on("newMessage", (messageData) => {
      if (onNewMessage) {
        onNewMessage(messageData);
      }
      // Increment unread count for the sender
      if (userData._id === messageData.receiver) {
        const receiverId = messageData.receiver;
        setUnreadMessages((prevCounts) => ({
          ...prevCounts,
          [receiverId]: (prevCounts[receiverId] || 0) + 1,
          [`lastMessage_${receiverId}`]: messageData.message,
        }));
      }
    });

    // Handle unread count updates from the server
    socket.on("unreadCount", ({ userId, unreadCount, lastMessage }) => {
      setUnreadMessages((prevCounts) => ({
        ...prevCounts,
        [userId]: unreadCount,
        [`lastMessage_${userId}`]: lastMessage,
      }));
    });

    // Listen for message status updates (Delivered/Read)
    socket.on("messageStatusUpdate", ({ messageId, status }) => {
      setChatHistory((prevMessages) => {
        const updatedMessages = prevMessages
          .filter(
            (msg) =>
              msg.sender === userData._id || msg.receiver === userData._id
          )
          .map((msg) => (msg._id === messageId ? { ...msg, status } : msg));
        return updatedMessages;
      });
    });

    socket.on("typing", ({ senderId }) => {
      if (onNewMessage) {
        onNewMessage({ senderId, typing: true });
      }
    });

    socket.on("stopTyping", ({ senderId }) => {
      if (onNewMessage) {
        onNewMessage({ senderId, typing: false });
      }
    });

    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("newMessage");
        socket.off("typing");
        socket.off("stopTyping");
        socket.off("userStatusChange");
        socket.off("messageStatusUpdate");
        socket.off("unreadCount");
        socket.off("disconnect");
      }
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    userData,
    setOnlineUsers,
    onNewMessage,
    unreadMessages,
    setChatHistory,
    receiver,
    setReceiver,
  ]);

  const startTyping = (receiverId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("typing", {
        senderId: userData._id,
        receiverId,
      });
    }
  };

  const stopTyping = (receiverId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("stopTyping", {
        senderId: userData._id,
        receiverId,
      });
    }
  };

  const joinChatRoom = (receiverId) => {
    if (socketRef.current && userData) {
      socketRef.current.emit("joinChatRoom", {
        senderId: userData._id,
        receiverId,
      });
    }
  };

  const sendMessage = (receiverId, message) => {
    if (socketRef.current) {
      socketRef.current.emit("newMessage", { receiverId, message });
    }
  };

  const markAsDelivered = (messageId, receiverId) => {
    if (socketRef.current) {
      socketRef.current.emit("messageDelivered", { messageId, receiverId });
    }
  };

  const markAsRead = (messageIdsToMarkRead, receiverId) => {
    if (socketRef.current) {
      socketRef.current.emit("messagesRead", {
        messageIds: messageIdsToMarkRead,
        receiverId,
      });
    }
  };

  return {
    socket: socketRef.current,
    sendMessage,
    joinChatRoom,
    startTyping,
    stopTyping,
    markAsDelivered,
    markAsRead,
    unreadMessages,
    setUnreadMessages,
  };
};

export default useSocket;
