import { createSlice } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        skillLevel: [],
        subcategory: [],
        customFilters: '',
        availability: [],
        priceRange: { min: "", max: "" },
        rating: "",
    },
    reducers: {
        setSkillLevels: (state, action) => {
            state.skillLevel = action.payload;
        },
        setSubcategories: (state, action) => {
            state.subcategory = action.payload;
        },
        setCustomFilters: (state, action) => {
            state.customFilters = action.payload;
        },
        setAvailability: (state, action) => {
            state.availability = action.payload;
        },
        setPriceRange: (state, action) => {
            state.priceRange = action.payload;
        },
        setRating: (state, action) => {
            state.rating = action.payload;
        },
        resetFilters: (state) => {
            state.skillLevel = [];
            state.subcategory = [];
            state.customFilters = '';
            state.availability = [];
            state.priceRange = { min: "", max: "" };
            state.rating = "";
        }
    }
});

export const {
    setSkillLevels,
    setSubcategories,
    setCustomFilters,
    setAvailability,
    setPriceRange,
    setRating,
    resetFilters
} = filtersSlice.actions;

export default filtersSlice.reducer;
