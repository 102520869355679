import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const formatFileSize = (size) => {
    if (size < 1024) {
        return `${size} bytes`;
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    } else {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
}

export function downloadFile(fileUrl, fileName) {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/download?fileUrl=${fileUrl}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('File download failed');
            }

            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error downloading file:', error);
        });
}

export const formatReviewDate = (createdAt) => {
    const reviewDate = dayjs(createdAt);
    const now = dayjs();

    if (now.diff(reviewDate, "month") >= 1) {
        return reviewDate.format("DD-MM-YYYY");
    }
    return reviewDate.fromNow();
};

export const formattedLastActive = (lastActive) => {
    const now = dayjs();
    const lastActiveTime = dayjs(lastActive);

    if (now.diff(lastActiveTime, 'days') === 0) {
        return `Last seen ${lastActiveTime.from(now)}`;
    }

    return `Last seen on ${lastActiveTime.format('MMMM D, YYYY [at] hh:mm A')}`;
};
