import { handleApiRequest } from "../../utils/handleApiRequest";

export const sendRequest = (formData) =>
    handleApiRequest("POST", "/send-request", formData);

export const responseRequest = (formData) =>
    handleApiRequest("POST", "/respond-request", formData);

export const connectedUser = ({ query } = {}) =>
    handleApiRequest("GET", query ? `/all-connections?${query}` : `/all-connections`);

export const allNotifications = () => handleApiRequest("GET", "/all-notifications")

export const getallRequest = () => handleApiRequest("GET", "/requests");

export const getPendingData = () => handleApiRequest("GET", "/pending-request");

export const clearNotification = () => handleApiRequest("PUT", "/clear-notification")