import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import logo from "../../assets/Images/MobileLogo.png";
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/react";

function AuthHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <>
      <div>
        <header>
          <div className="bg-primary sm:p-[16px_40px] p-[16px] flex items-center justify-between">
            <div className="flex items-center gap-28">
              <img src={logo} alt="" className="w-[60px]" />
              <ul class="lg:flex items-center gap-[25px] hidden">
                <li class=" border-gray-300">
                  <Link
                    to="/"
                    class=" hover:text-white text-white block font-semibold text-[18px]"
                  >
                    Home
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to="/course"
                    class=" hover:text-white text-white block font-semibold text-[18px]"
                  >
                    Course
                  </Link>
                </li>
              </ul>
            </div>

            <div className="lg:hidden block">
              <CgMenu
                className="w-[25px] h-[25px] text-white "
                onClick={() => setToggleDropdown(true)}
              />
            </div>

            <div className="hidden lg:flex items-center gap-[20px]">
              <Link
                to="/signup"
                className=" flex items-center justify-center sm:text-[18px] text-[12px] font-medium p-[12px] sm:w-[151px] w-[112px] sm:h-[40px] h-[35px] border border-white rounded-md bg-gray1 text-primary"
              >
                Create Account
              </Link>

              <Link
                to="/login"
                className="flex items-center justify-center sm:text-[18px] text-[12px] font-medium p-[12px] sm:w-[83px] w-[72px] sm:h-[40px] h-[35px]  bg-gray1 text-primary rounded-md"
              >
                Sign In
              </Link>
            </div>
            <Dialog
              open={toggleDropdown}
              onClose={setToggleDropdown}
              className="lg:hidden"
            >
              <div className="fixed inset-0 z-10 bg-[#00000038]" />
              <DialogPanel className="fixed inset-y-0 left-0 z-10 top-0 overflow-y-auto bg-white p-[20px] max-w-[300px] sm:ring-1 sm:ring-gray-900/10 h-full">
                <div className="flex items-center justify-end">
                  {/* <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button> */}
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="pb-[20px]">
                      <ul className="py-2">
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="/"> Home</Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="/aboutUs">About us</Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="/course"> Courses</Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-primary hover:text-white">
                          <Link to="/contactUs">Contact us</Link>
                        </li>
                      </ul>
                      <div className="flex gap-[22px] lg:flex-row flex-col">
                        {/* {!isAuthenticated() ? (
                      <div className="flex gap-[8px]">
                        <Link
                          to="/login"
                          className="p-2 lg:px-4 text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                        >
                          Log in
                        </Link>
                        <Link
                          to="/signup"
                          className="p-2 lg:px-4 text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                        >
                          Sign up
                        </Link>
                      </div>
                    ) : (
                      <div className="flex gap-[8px]">
                        <Link
                          to={`${role}/dashboard`}
                          className="p-2 lg:px-4 text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                        >
                          Dashboard
                        </Link>
                      </div>
                    )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </Dialog>

            {/* <div>
              <img src={logo} alt="" className="w-[60px]" />
            </div>
            <div>
              <ul class="lg:flex items-center gap-[25px] hidden">
                <li class=" border-gray-300">
                  <Link
                    to="/"
                    class=" hover:text-white text-white block font-semibold text-[18px]"
                  >
                    Home
                  </Link>
                </li>
                <li class=" border-gray-300">
                  <Link
                    to="/course"
                    class=" hover:text-white text-white block font-semibold text-[18px]"
                  >
                    Course
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <Link
                to="/signup"
                className=" flex items-center justify-center sm:text-[18px] text-[12px] font-medium p-[12px] sm:w-[151px] w-[112px] sm:h-[40px] h-[35px] border border-white rounded-md bg-gray1 text-primary"
              >
                Create Account
              </Link>

              <Link
                to="/login"
                className="flex items-center justify-center sm:text-[18px] text-[12px] font-medium p-[12px] sm:w-[83px] w-[72px] sm:h-[40px] h-[35px]  bg-gray1 text-primary rounded-md"
              >
                Sign In
              </Link>
            </div> */}
          </div>
        </header>
      </div>
    </>
  );
}

export default AuthHeader;
