import { MdOutlineMessage, MdOutlinePersonOutline, MdOutlineSpaceDashboard } from "react-icons/md";
import YOUNGMAN1 from "../assets/Images/youngman1.png";
import YOUNGMAN2 from "../assets/Images/youngman2.png";
import YOUNGMAN3 from "../assets/Images/youngman3.png";
import YOUNGMAN4 from "../assets/Images/youngman4.png";
import YOUNGMAN5 from "../assets/Images/youngman5.png";
import YOUNGMAN6 from "../assets/Images/youngman6.png";
import YOUNGMAN7 from "../assets/Images/youngman7.png";
import YOUNGMAN8 from "../assets/Images/youngman8.png";
import YOUNGMAN9 from "../assets/Images/youngman9.png";
import { FaChalkboardUser, FaRegCalendarCheck } from "react-icons/fa6";
import { CgAddR } from "react-icons/cg";
import { SlLayers } from "react-icons/sl";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { AiFillStar, AiOutlinePayCircle } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";

export const menus = [
    {
        label: "Dashboard",
        icon: MdOutlineSpaceDashboard,
        link: (role) => `/${role}/dashboard`,
        roleRestriction: null,
    },
    {
        label: "My Schedule",
        icon: FaRegCalendarCheck,
        link: () => "/schedule",
        roleRestriction: null,
    },
    {
        label: "Create New Class",
        icon: CgAddR,
        link: () => "/create-classes",
        roleRestriction: (role) => role !== "student",
        disabledCondition: (isSetProfile) => !isSetProfile,
    },
    {
        label: "My Classes",
        icon: SlLayers,
        link: () => "/my-classes",
        roleRestriction: null,
    },
    {
        label: "All Classes",
        icon: FaChalkboardUser,
        link: () => "/course",
        roleRestriction: (role) => role === "student",
    },
    {
        label: "Find Teacher / My Students",
        icon: FiUsers,
        link: (role) => (role === "student" ? "/teacher/list" : "/student/list"),
        roleRestriction: null,
    },
    {
        label: "Financial Details",
        icon: HiOutlineCreditCard,
        link: () => "#",
        roleRestriction: null,
    },
    {
        label: "Message",
        icon: MdOutlineMessage,
        link: () => "/chat",
        roleRestriction: null,
    },
    {
        label: "Subscription",
        icon: AiOutlinePayCircle,
        link: () => "#",
        roleRestriction: null,
    },
    {
        label: "Settings",
        icon: IoSettingsOutline,
        link: (role) => `/${role}/profile`,
        roleRestriction: null,
    },
];

export const Subscription = [
    {
        id: 1,
        name: "Silver",
        value: "silver",
    },
    {
        id: 2,
        name: "Platinum",
        value: "platinum",
    },
    {
        id: 3,
        name: "Gold",
        value: "gold",
    },
];

export const Availability = [
    {
        id: 1,
        name: "UTC (Coordinated Universal Time)",
        value: "UTC",
    },
    {
        id: 2,
        name: "IST (Indian Standard Time, UTC+5:30)",
        value: "IST",
    },
    {
        id: 3,
        name: "EST (Eastern Standard Time, UTC-5)",
        value: "EST",
    },
    {
        id: 4,
        name: "PST (Pacific Standard Time, UTC-8)",
        value: "PST",
    },
    {
        id: 5,
        name: "GMT (Greenwich Mean Time, UTC+0)",
        value: "GMT",
    },
    {
        id: 6,
        name: "JST (Japan Standard Time, UTC+9)",
        value: "JST",
    },
    {
        id: 7,
        name: "AEST (Australian Eastern Standard Time, UTC+10)",
        value: "AEST",
    },
    {
        id: 8,
        name: "CST (Central Standard Time, UTC-6)",
        value: "CST",
    },
    {
        id: 9,
        name: "MST (Mountain Standard Time, UTC-7)",
        value: "MST",
    },
    {
        id: 10,
        name: "CET (Central European Time, UTC+1)",
        value: "CET",
    },
    {
        id: 11,
        name: "BST (British Summer Time, UTC+1)",
        value: "BST",
    },
    {
        id: 12,
        name: "SGT (Singapore Time, UTC+8)",
        value: "SGT",
    },
    {
        id: 13,
        name: "CST (China Standard Time, UTC+8)",
        value: "CST",
    },
    {
        id: 14,
        name: "NZST (New Zealand Standard Time, UTC+12)",
        value: "NZST",
    },
    {
        id: 15,
        name: "BRT (Brasilia Time, UTC-3)",
        value: "BRT",
    },
    {
        id: 16,
        name: "SAST (South African Standard Time, UTC+2)",
        value: "SAST",
    },
    {
        id: 17,
        name: "MSK (Moscow Standard Time, UTC+3)",
        value: "MSK",
    },
    {
        id: 18,
        name: "KST (Korean Standard Time, UTC+9)",
        value: "KST",
    },
    {
        id: 19,
        name: "GST (Gulf Standard Time, UTC+4)",
        value: "GST",
    },
    {
        id: 20,
        name: "PHT (Philippine Time, UTC+8)",
        value: "PHT",
    },
];

export const TeachersTab = ["Personal Settings", "Skill Matrix", "Fee Structure", "Setting"];
export const StudentTab = ["Personal Settings", "Financials", "Setting"];

export const rating = [
    { star: <AiFillStar />, name: "5 Star", value: 5 },
    { star: <AiFillStar />, name: "4 Star & up", value: 4 },
    { star: <AiFillStar />, name: "3 Star & up", value: 3 },
    { star: <AiFillStar />, name: "2 Star & up", value: 2 },
    { star: <AiFillStar />, name: "1 Star & up", value: 1 },
];

export const Coupon = [
    {
        id: 1,
        name: "Yes",
        value: true,
    },
    {
        id: 2,
        name: "No",
        value: false,
    },
];

export const Source = [
    { id: 1, name: "Google", value: "google" },
    { id: 2, name: "Friend or Family", value: "friend_or_family" },
    { id: 3, name: "Blog", value: "blog" },
    { id: 4, name: "Advertisement", value: "advertisement" },
    { id: 5, name: "Referral", value: "referral" },
    { id: 6, name: "Other", value: "other" },
];

export const SkillLevel = [
    {
        id: 1,
        name: "Beginner",
        value: "beginner",
    },
    {
        id: 2,
        name: "Intermediate",
        value: "intermediate",
    },
    {
        id: 3,
        name: "Advanced",
        value: "advanced",
    },
];

export const Role = [
    {
        id: 1,
        name: "Teacher",
        value: "teacher",
    },
    {
        id: 2,
        name: "Student",
        value: "student",
    },
];

export const Interest = [
    { id: 1, name: "Music", value: "music" },
    { id: 2, name: "Dancing", value: "dancing" },
    { id: 3, name: "Singing", value: "singing" },
    { id: 4, name: "Painting", value: "painting" },
    { id: 5, name: "Drawing", value: "drawing" },
    { id: 6, name: "Photography", value: "photography" },
    { id: 7, name: "Sports", value: "sports" },
    { id: 8, name: "Cooking", value: "cooking" },
    { id: 10, name: "Reading", value: "reading" },
    { id: 11, name: "Writing", value: "writing" },
    { id: 12, name: "Technology", value: "technology" },
    { id: 13, name: "Gardening", value: "gardening" },
    { id: 14, name: "Crafting", value: "crafting" },
    { id: 15, name: "Fitness", value: "fitness" },
    { id: 16, name: "Volunteering", value: "volunteering" },
    { id: 17, name: "Other", value: "other" },
];

export const userData = [
    {
        id: 1,
        name: "Riyas",
        specialty: "Ballet",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 15,
        image: YOUNGMAN1,
    },
    {
        id: 2,
        name: "Revanth Thiyagarajan",
        specialty: "Specialty: Contemporary Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN2,
    },
    {
        id: 3,
        name: "Iyyappan",
        specialty: "Specialty: Tap Dance, Ballroom Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN3,
    },
    {
        id: 4,
        name: "Abi",
        specialty: "Hip-Hop, Pop Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN4,
    },
    {
        id: 5,
        name: "Divya",
        specialty: "Specialty: Latin Dance, Belly Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN5,
    },
    {
        id: 6,
        name: "Vijay",
        specialty: "Specialty: Musical Theatre, Tap Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN6,
    },
    {
        id: 7,
        name: "Dinesh",
        specialty: "Specialty: Modern Dance, Dance Theatre",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN7,
    },
    {
        id: 8,
        name: "Babu",
        specialty: "Specialty: Tap Dance",
        level: "Intermediate & Advance",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN8,
    },
    {
        id: 9,
        name: "Alvin Ailey",
        specialty: "Specialty: Tap Dance",
        level: "Beginner",
        rating: 5.0,
        students: 236568,
        price: 12,
        image: YOUNGMAN9,
    },
];

export const Session = [
    {
        id: 1,
        name: "Regular class",
        value: "regular_class",
    },
    {
        id: 2,
        name: "Makeup Class",
        value: "makeup_class",
    },
];

export const ClassType = [
    {
        id: 1,
        name: "Individual",
        value: "individual",
    },
    {
        id: 2,
        name: "Group",
        value: "group",
    },
];

export const language = [
    {
        id: 1,
        name: "Hindi",
        value: "hindi",
    },
    {
        id: 2,
        name: "Gujarati",
        value: "gujarati",
    },
    {
        id: 3,
        name: "Marathi",
        value: "marathi",
    },
    {
        id: 4,
        name: "English",
        value: "english",
    },
    {
        id: 5,
        name: "Spanish",
        value: "spanish",
    },
    {
        id: 6,
        name: "French",
        value: "french",
    },
    {
        id: 7,
        name: "German",
        value: "german",
    },
    {
        id: 8,
        name: "Italian",
        value: "italian",
    },
    {
        id: 9,
        name: "Portuguese",
        value: "portuguese",
    },
    {
        id: 10,
        name: "Russian",
        value: "russian",
    },
    {
        id: 11,
        name: "Arabic",
        value: "arabic",
    },
    {
        id: 12,
        name: "Chinese (Simplified)",
        value: "chinese_simplified",
    },
    {
        id: 13,
        name: "Japanese",
        value: "japanese",
    },
    {
        id: 14,
        name: "Korean",
        value: "korean",
    },
    {
        id: 15,
        name: "Turkish",
        value: "turkish",
    },
    {
        id: 16,
        name: "Dutch",
        value: "dutch",
    },
    {
        id: 17,
        name: "Swedish",
        value: "swedish",
    },
    {
        id: 18,
        name: "Polish",
        value: "polish",
    },
    {
        id: 19,
        name: "Finnish",
        value: "finnish",
    },
    {
        id: 20,
        name: "Greek",
        value: "greek",
    },
    {
        id: 21,
        name: "Tamil",
        value: "tamil",
    },
    {
        id: 22,
        name: "Telugu",
        value: "telugu",
    },
    {
        id: 23,
        name: "Kannada",
        value: "kannada",
    },
    {
        id: 24,
        name: "Malayalam",
        value: "malayalam",
    },
    {
        id: 25,
        name: "Bengali",
        value: "bengali",
    },
    {
        id: 26,
        name: "Punjabi",
        value: "punjabi",
    },
    {
        id: 27,
        name: "Urdu",
        value: "urdu",
    },
    {
        id: 28,
        name: "Sinhala",
        value: "sinhala",
    },
    {
        id: 29,
        name: "Thai",
        value: "thai",
    },
    {
        id: 30,
        name: "Vietnamese",
        value: "vietnamese",
    },
    {
        id: 31,
        name: "Tagalog",
        value: "tagalog",
    },
    {
        id: 32,
        name: "Hungarian",
        value: "hungarian",
    },
    {
        id: 33,
        name: "Czech",
        value: "czech",
    },
    {
        id: 34,
        name: "Slovak",
        value: "slovak",
    },
    {
        id: 35,
        name: "Malay",
        value: "malay",
    },
    {
        id: 36,
        name: "Burmese",
        value: "burmese",
    },
    {
        id: 37,
        name: "Nepali",
        value: "nepali",
    },
    {
        id: 38,
        name: "Lao",
        value: "lao",
    },
    {
        id: 39,
        name: "Khmer",
        value: "khmer",
    },
    {
        id: 40,
        name: "Tibetan",
        value: "tibetan",
    },
];

export const frequency = [
    {
        id: 1,
        name: "Daily",
        value: "daily",
    },
    {
        id: 2,
        name: "Custom Days",
        value: "custom days",
    },
];

export const daysOfWeek = [
    {
        id: 1,
        name: "Sunday",
        value: "sunday",
    },
    {
        id: 2,
        name: "Monday",
        value: "monday",
    },
    {
        id: 3,
        name: "Tuesday",
        value: "tuesday",
    },
    {
        id: 4,
        name: "Wednesday",
        value: "wednesday",
    },
    {
        id: 5,
        name: "Thursday",
        value: "thursday",
    },
    {
        id: 6,
        name: "Friday",
        value: "friday",
    },
    {
        id: 7,
        name: "Saturday",
        value: "saturday",
    },
];

export const Currencies = [
    {
        id: 1,
        symbol: "$",
        code: "USD",
        name: "US Dollar",
        value: "us_doller",
    },
    {
        id: 2,
        symbol: "€",
        code: "EUR",
        name: "Euro",
        value: "euro",
    },
    {
        id: 3,
        symbol: "£",
        code: "GBP",
        name: "British Pound",
        value: "british_pound",
    },
    {
        id: 4,
        symbol: "₹",
        code: "INR",
        name: "Indian Rupee",
        value: "indian_rupee",
    },
    {
        id: 5,
        symbol: "¥",
        code: "JPY",
        name: "Japanese Yen",
        value: "japanese_yen",
    },
];