import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import CommonLayout from "../../components/common/CommonLayout";
import useSocket from "../../hooks/useSocket";
import useUserData from "../../hooks/useUserData";
import {
  getChatHistory,
  getChatUserList,
  sendChat,
} from "../../services/apis/ChatApis";
import { IoSend } from "react-icons/io5";
import { useOnlineUsers } from "../../contexts/OnlineUserContext";
import { formattedLastActive } from "../../utils/utils";
import { MdKeyboardArrowLeft } from "react-icons/md";
import ChatUserLoader from "../../components/skeleton/ChatUserLoader";
import { PiChatsBold } from "react-icons/pi";

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

function Chat() {
  const { onlineUsers, chatHistory, setChatHistory, receiver, setReceiver } =
    useOnlineUsers();
  const [isChatWindowVisible, setIsChatWindowVisible] = useState(false);
  const [loadUser, setLoadUser] = useState(true);
  const userData = useUserData();
  const [typingUser, setTypingUser] = useState(null);
  const [page, setPage] = useState(1);
  const [loadChat, setLoadChat] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const containerRef = useRef(null);

  const onNewMessage = (messageData) => {
    if (messageData.typing) {
      setTypingUser(messageData);
    } else if (messageData.typing === false) {
      setTypingUser(null);
    } else {
      setChatHistory((prevMessages) => [
        ...prevMessages,
        {
          sender: messageData.sender,
          receiver: messageData.receiver,
          message: messageData.message,
          createdAt: messageData.createdAt,
          status: messageData.status,
          _id: messageData._id,
        },
      ]);
    }
  };

  const {
    joinChatRoom,
    sendMessage,
    startTyping,
    stopTyping,
    unreadMessages,
    markAsRead,
    setUnreadMessages,
  } = useSocket(onNewMessage);

  useEffect(() => {
    if (chatHistory.length !== 0 && receiver) {
      const messagesToMarkAsRead = chatHistory
        .filter(
          (msg) =>
            msg.status !== "read" &&
            msg.receiver === userData._id &&
            msg.sender === receiver._id
        )
        .map((msg) => msg._id);

      if (messagesToMarkAsRead.length > 0) {
        markAsRead(messagesToMarkAsRead, userData._id);
      }
    }
  }, [chatHistory, markAsRead, receiver, userData._id]);

  const isUserOnline = (userId) => onlineUsers.has(userId);

  const handleTyping = (e) => {
    setMessage(e.target.value);

    if (e.target.value) {
      startTyping(receiver._id);
    } else {
      stopTyping(receiver._id);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() && receiver) {
      await sendChat({ receiverId: receiver._id, message });
      sendMessage(receiver._id, message);
      setMessage("");
      stopTyping(receiver._id);
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputResize = (e) => {
    e.target.style.height = "45px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (receiver) {
      setPage(1);
      setChatHistory([]);
      loadMessages(1);
    }
  }, [receiver]);

  const loadMessages = async (pageNumber = 1) => {
    if (loadChat) return;
    setLoadChat(true);

    try {
      const data = await getChatHistory(receiver._id, pageNumber);
      if (data.success) {
        setChatHistory((prevMessages) => [...data.data, ...prevMessages]);
        setPage((prevPage) => prevPage + 1);
      } else {
        console.error("Error fetching chat history:", data.message);
      }
    } catch (error) {
      console.error("Error loading messages:", error);
    }

    setLoadChat(false);
  };

  const handleScroll = () => {
    if (containerRef.current.scrollTop === 0) {
      loadMessages(page);
    }
  };

  const fetchUserList = async () => {
    try {
      const data = await getChatUserList();
      const initialUnreadMessages = data.users.reduce((acc, user) => {
        acc[user._id] = user.unreadCount || 0;
        acc[`lastMessage_${user._id}`] = user.lastMessage || "";
        return acc;
      }, {});
      setUsers(data.users);
      setUnreadMessages(initialUnreadMessages);
    } catch (error) {
    } finally {
      setLoadUser(false);
    }
  };

  useEffect(() => {
    if (receiver) {
      joinChatRoom(receiver._id);
    }
  }, [receiver]);

  useEffect(() => {
    fetchUserList();
  }, [onlineUsers]);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // }, [chatHistory]);

  return (
    <CommonLayout title={"Chat"}>
      <div>
        {loadUser ? (
          <ChatUserLoader />
        ) : (
          <div>
            {users.length !== 0 ? (
              <div className="flex shadow-[rgba(100,100,111,0.2)0px_7px_29px_0px] h-[calc(100vh-54px)]">
                <div
                  className={`md:block ${
                    isChatWindowVisible ? "hidden" : "block"
                  } w-full md:w-[300px] bg-white pb-2 h-[calc(100vh-54px)] overflow-auto border-r border-gray-200`}
                >
                  <div>
                    {users?.map((user) => (
                      <div
                        className="p-[10px_18px] cursor-pointer hover:bg-lightblue border-b"
                        key={user._id}
                        onClick={() => {
                          if (!receiver || receiver._id !== user._id) {
                            setChatHistory([]);
                            setMessage("");
                          }
                          setIsChatWindowVisible(true);
                          setReceiver(user);
                          setUnreadMessages((prevCounts) => ({
                            ...prevCounts,
                            [user._id]: 0,
                            [`lastMessage_${user._id}`]: user.lastMessage || "",
                          }));
                        }}
                      >
                        <div className="flex items-start justify-between gap-2">
                          <div className="flex items-center justify-between gap-[12px] w-full">
                            <div className="relative">
                              <img
                                src={user.profileImageUrl}
                                alt=""
                                className="min-w-[40px] w-[40px] h-[40px] min-h-[40px] rounded-full"
                              />
                              {isUserOnline(user._id)?.status === true ||
                              user.isOnline ? (
                                <div className="bottom-[-2px] right-0 w-3 h-3 bg-green-500 border border-white rounded-full absolute"></div>
                              ) : null}
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <div className="flex items-start flex-col">
                                <h1 className="text-[16px] text-black font-medium mb-0">
                                  {user.firstName + " " + user.lastName}
                                </h1>
                                <p className="text-[14px] text-gray1 font-normal mb-0 w-full max-w-[200px] truncate">
                                  {unreadMessages[`lastMessage_${user._id}`] ||
                                    user.lastMessage}
                                </p>
                              </div>
                              {unreadMessages[user._id] > 0 && (
                                <div className="w-4 h-4 bg-primary rounded-full text-white text-[10px] font-medium flex justify-center items-center">
                                  {unreadMessages[user._id]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className={`flex-1 h-[calc(100vh-54px)] overflow-auto bg-white flex-col ${
                    isChatWindowVisible ? "block" : "hidden"
                  } md:block`}
                >
                  {receiver && (
                    <div>
                      {/* Receiver Info */}
                      <div className="p-[11px_14px] flex items-center gap-1 border-b border-b-[#ACACAC]">
                        <button onClick={() => setIsChatWindowVisible(false)}>
                          <MdKeyboardArrowLeft className="text-4xl lg:hidden text-primary" />
                        </button>
                        <div className="flex gap-[12px]">
                          <img
                            src={receiver.profileImageUrl}
                            alt=""
                            className="w-[40px] h-[40px] rounded-full"
                          />
                          <div className="flex items-start flex-col">
                            <h1 className="sm:text-[18px] text-[16px] text-black font-semibold mb-0">
                              {receiver.firstName + " " + receiver.lastName}
                            </h1>
                            <p className="text-[12px] font-normal mb-0">
                              {receiver.isOnline ? (
                                <p className="text-green-600">Active Now</p>
                              ) : (
                                <p>
                                  {formattedLastActive(receiver.lastActive)}
                                </p>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Chat Messages */}
                      <div
                        ref={containerRef}
                        onScroll={handleScroll}
                        className={`h-[calc(100vh-200px)] overflow-auto p-3 my-scroll flex flex-col ${
                          chatHistory.length === 0 &&
                          "justify-center items-center"
                        } `}
                      >
                        {chatHistory
                          .filter(
                            (msg) =>
                              (msg.sender === userData._id &&
                                msg.receiver === receiver._id) ||
                              (msg.sender === receiver._id &&
                                msg.receiver === userData._id)
                          )
                          .map((msg, index) => (
                            <div
                              key={index}
                              className={`flex ${
                                msg.sender === userData._id
                                  ? "justify-end"
                                  : "justify-start"
                              }`}
                            >
                              {/* Sender message */}
                              {msg.sender === userData._id ? (
                                <div className="sm:my-2 my-[10px] flex items-end flex-col">
                                  <h3 className="text-[10px] text-gray1 font-normal">
                                    {dayjs(msg.createdAt).format("h:mm A")}
                                  </h3>
                                  <p className="w-full 2xl:max-w-[700px] max-w-[500px] bg-primary sm:text-[14px] text-[12px] text-white font-normal p-[10px_18px] break-words rounded-lg">
                                    {msg.message}
                                  </p>
                                  {/* Message status indicator */}
                                  {msg.status === "delivered" && (
                                    <div className="text-[10px] text-gray-600">
                                      Delivered
                                    </div>
                                  )}
                                  {msg.status === "read" && (
                                    <div className="text-[10px] text-gray-600">
                                      Read
                                    </div>
                                  )}
                                  {msg.status === "sent" && (
                                    <div className="text-[10px] text-gray-600">
                                      Sent
                                    </div>
                                  )}
                                </div>
                              ) : (
                                // Receiver message
                                <div className="sm:my-2 my-[10px] flex items-start flex-col">
                                  <h3 className="text-[10px] text-gray1 font-normal">
                                    {dayjs(msg.createdAt).format("h:mm A")}
                                  </h3>
                                  <div className="bg-lightblue p-[10px_18px] rounded-lg sm:text-[14px] text-[12px] text-nevyblue font-normal">
                                    {msg.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}

                        {chatHistory.length === 0 && (
                          <div className="flex items-center  flex-col">
                            <PiChatsBold className="text-5xl text-[#8c8b8b]" />
                            <div className="text-[#8c8b8b]">
                              You have no Conversation yet.
                            </div>
                          </div>
                        )}

                        {typingUser && typingUser.senderId === receiver._id && (
                          <div className="sm:my-[15px] my-[10px] flex items-start flex-col">
                            <div className="bg-lightblue p-[8px] sm:text-[14px] text-[12px] text-nevyblue font-normal">
                              Typing...
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="border-t border-t-[#ACACAC] p-4">
                        <div className="flex items-end justify-between gap-2">
                          <textarea
                            value={message}
                            onChange={handleTyping}
                            onKeyDown={handleKeyDown}
                            onInput={handleInputResize}
                            className="w-full sm:text-[14px] text-[12px] h-[45px] font-medium text-black placeholder:text-secondary bg-lightblue rounded-lg p-[12px_18px] outline-none resize-none"
                            placeholder="Type your message"
                          />
                          <button
                            onClick={handleSendMessage}
                            className="sm:text-[14px] text-[12px] text-white font-normal sm:w-[120px] w-[100px] sm:h-[45px] h-[32px] bg-primary flex items-center justify-center gap-4 rounded-lg"
                          >
                            Send <IoSend />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="bg-white shadow-[rgba(100,100,111,0.2)0px_7px_29px_0px] h-[calc(100vh-170px)] flex justify-center items-center">
                <p>No Users Found</p>
              </div>
            )}
          </div>
        )}
      </div>
    </CommonLayout>
  );
}

export default Chat;
