import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../components/common/Loader";
import Chat from "../chat/Chat";
// import Meeting from "../../components/meeting/Meeting";
const LandingPage = React.lazy(() => import("../landingPage/LandingPage"));
const NotFound = React.lazy(() => import("../notFound/NotFound"));
const Login = React.lazy(() => import("../auth/login/Login"));
const Signup = React.lazy(() => import("../auth/signup/Signup"));
const ForgotPassword = React.lazy(() =>
  import("../auth/forgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("../auth/resetPassword/ResetPassword")
);
const ReactiveAccount = React.lazy(() =>
  import("../auth/reactiveAccount/ReactiveAccount")
);
const VerifyOtp = React.lazy(() => import("../auth/verifyOtp/VerifyOtp"));
const TeacherProfile = React.lazy(() =>
  import("../teachers/TeacherProfile/TeacherProfile")
);
const StudentProfile = React.lazy(() =>
  import("../students/studentProfile/StudentProfile")
);
const NewCourse = React.lazy(() => import("../course/newCourse/NewCourse"));
const CourseList = React.lazy(() => import("../course/courseList/CourseList"));
const Schedule = React.lazy(() => import("../schedule/mySchedule/Schedule"));
const AboutUs = React.lazy(() => import("../landingPage/aboutus/Aboutus"));
const ContactUs = React.lazy(() =>
  import("../landingPage/contactus/Contactus")
);
const TeacherDashboard = React.lazy(() =>
  import("../teachers/teacherDashboard/TeacherDashboard")
);
const StudentDashboard = React.lazy(() =>
  import("../students/studentDashboard/StudentDashboard")
);
const StudentList = React.lazy(() =>
  import("../students/studentList/StudentList")
);
const StudentDetails = React.lazy(() =>
  import("../students/studentDetails/StudentDetails")
);
const Financials = React.lazy(() => import("../financials/Financials"));
const TeacherList = React.lazy(() =>
  import("../teachers/teacherList/TeacherList")
);
const ClassDetails = React.lazy(() =>
  import("../course/classDetails/ClassDetails")
);
const MyClassDetails = React.lazy(() =>
  import("../course/myClassDetails/MyClassDetails")
);
const TeacherDetails = React.lazy(() =>
  import("../teachers/teacherDetails/TeacherDetails")
);
const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));
const MyClasses = React.lazy(() => import("../course/myClasses/MyClasses"));

function Router() {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* ================================ AUTHENTICATION ROUTES =================================== */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/reactive-account" element={<ReactiveAccount />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/*" element={<NotFound />} />

          {/* ================================== TEACHER ROUTES ====================================== */}
          <Route
            path="/teacher/profile"
            element={
              <ProtectedRoute
                element={<TeacherProfile />}
                allowedRoles={["teacher"]}
              />
            }
          />
          <Route
            path="/teacher/dashboard"
            element={
              <ProtectedRoute
                element={<TeacherDashboard />}
                allowedRoles={["teacher"]}
              />
            }
          />
          <Route
            path="/teacher/list"
            // element={
            //   <ProtectedRoute
            //     element={<TeacherList />}
            //     allowedRoles={["teacher", "student"]}
            //   />
            // }
            element={<TeacherList />}
          />
          <Route
            path="/teacher/details/:id"
            element={<TeacherDetails />}
            // element={<ProtectedRoute element={<TeacherDetails />} />}
          />

          {/* ================================== STUDENT ROUTES ====================================== */}
          <Route
            path="/student/profile"
            element={
              <ProtectedRoute
                element={<StudentProfile />}
                allowedRoles={["student"]}
              />
            }
          />
          <Route
            path="/student/dashboard"
            element={
              <ProtectedRoute
                element={<StudentDashboard />}
                allowedRoles={["student"]}
              />
            }
          />
          <Route path="/student/details/:id" element={<StudentDetails />} />
          {/* <Route path="/meeting" element={<Meeting />} /> */}
          <Route
            path="/student/list"
            element={<ProtectedRoute element={<StudentList />} />}
          />

          {/* ================================== CHAT ROUTES ====================================== */}

          <Route path="/chat" element={<Chat />} />

          {/* ================================== CLASS ROUTES ====================================== */}
          <Route path="/course" element={<CourseList />} />
          <Route
            path="/schedule"
            element={<ProtectedRoute element={<Schedule />} />}
          />
          <Route
            path="/create-classes"
            element={
              <ProtectedRoute
                element={<NewCourse />}
                allowedRoles={["teacher"]}
              />
            }
          />
          <Route
            path="/edit-classes/:id"
            element={
              <ProtectedRoute
                element={<NewCourse />}
                allowedRoles={["teacher"]}
              />
            }
          />
          <Route
            path="/my-classes"
            element={
              <ProtectedRoute
                element={<MyClasses />}
                allowedRoles={["teacher", "student"]}
              />
            }
          />
          <Route path="/class-detail/:classId" element={<ClassDetails />} />

          <Route path="/my-class-detail/:id" element={<MyClassDetails />} />
          {/* ================================== FINANCIALS ROUTES ====================================== */}
          <Route
            path="/financials"
            element={<ProtectedRoute element={<Financials />} />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default Router;
