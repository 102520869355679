import { handleApiRequest } from "../../utils/handleApiRequest";

export const sendChat = (formData) =>
    handleApiRequest("POST", "/send-message", formData);

export const getChatUserList = () => handleApiRequest("GET", "/chat-users");

export const markAsRead = (messageId) =>
    handleApiRequest("PUT", `/read/${messageId}`);

export const getChatHistory = (receiverId, page) =>
    handleApiRequest("GET", `/chat-history?receiverId=${receiverId}&page=${page}`);

export const createGroup = (formData) =>
    handleApiRequest("POST", "/create-group", formData);

export const checkStatus = (status) =>
    handleApiRequest("PUT", "/check-status", { isOnline: status });