import { useSelector } from "react-redux";
import { getUserRole } from "../pages/router/ProtectedRoute";

const useUserData = () => {
  const role = getUserRole();
  const studentProfile = useSelector((state) => state.studentProfile);
  const teacherProfile = useSelector((state) => state.teacherProfile);

  return role === "student" ? studentProfile : teacherProfile;
};

export default useUserData;
