import { useState } from "react";
import LOGOFOOTER from "../assets/Images/Logofooter.png";
import MOBILELOGO from "../assets/Images/MobileLogo.png";
import { getUserRole, isAuthenticated } from "../pages/router/ProtectedRoute";
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BiSearch } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useSearch } from "../contexts/SearchContext";

function Header() {
  const role = getUserRole();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { searchTerm, setSearchTerm } = useSearch();
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate("/teacher/list");
  };
  return (
    <>
      <header className="fixed top-0 left-0 w-full z-10 bg-primary py-2">
        <nav
          aria-label="Global"
          className="mx-auto flex items-center justify-between xl:px-[80px] md:px-[40px] px-[20px]"
        >
          <div className="flex lg:flex-1">
            <button className="flex items-center -m-1.5 p-1.5">
              <img
                src={LOGOFOOTER}
                alt=""
                className="w-full max-w-[70px] lg:block hidden "
              />
              <img
                src={MOBILELOGO}
                alt=""
                className="w-[45px] h-[45px] lg:hidden block"
              />
            </button>
          </div>
          <div className="block lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <PopoverGroup
            className={`hidden lg:flex items-center ${
              !isAuthenticated() ? "gap-2 xl:gap-5" : "gap-5"
            }`}
          >
            <ul
              className={`flex ${
                !isAuthenticated() ? "gap-2 xl:gap-5" : "gap-5"
              } font-medium`}
            >
              <li>
                <Link
                  to="/"
                  className={`p-2 ${
                    !isAuthenticated() ? "px-2 xl:px-4" : "px-4"
                  } text-white font-medium text-lg hover:text-gray-200 transition-all duration-300`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutUs"
                  className={`p-2 ${
                    !isAuthenticated() ? "px-2 xl:px-4" : "px-4"
                  } text-white font-medium text-lg hover:text-gray-200 transition-all duration-300`}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/course"
                  className={`p-2 ${
                    !isAuthenticated() ? "px-2 xl:px-4" : "px-4"
                  } text-white font-medium text-lg hover:text-gray-200 transition-all duration-300`}
                >
                  Courses
                </Link>
              </li>
              <li>
                <Link
                  to="/contactus"
                  className={`p-2 ${
                    !isAuthenticated() ? "px-2 xl:px-4" : "px-4"
                  } text-white font-medium text-lg hover:text-gray-200 transition-all duration-300`}
                >
                  Contact us
                </Link>
              </li>
            </ul>
            <div
              className={`flex ${
                !isAuthenticated() ? "gap-2 xl:gap-5" : "gap-5"
              } items-center`}
            >
              <div className="w-full sm:max-w-[242px]">
                <div className="relative flex items-center bg-white rounded-lg">
                  <BiSearch className="absolute w-5 h-5 top-1/2 left-2.5 transform -translate-y-1/2 text-gray-600" />
                  <input
                    className="w-full placeholder:text-gray-400 text-black text-[16px] font-medium bg-white rounded-lg pl-10 pr-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200 ease-in-out shadow"
                    placeholder="Search for teachers"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  />
                </div>
              </div>
              {!isAuthenticated() ? (
                <div className="flex gap-2">
                  <Link
                    to="/login"
                    className="p-2 lg:px-4 text-center border-2 border-white text-white text-lg font-medium rounded-full flex justify-center items-center w-28 xl:w-32 h-11 hover:bg-primary hover:text-white transition-all duration-300"
                  >
                    Log in
                  </Link>
                  <Link
                    to="/signup"
                    className="p-2 lg:px-4 text-center border-2 border-white text-white text-lg font-medium rounded-full flex justify-center items-center w-28 xl:w-32 h-11 hover:bg-primary hover:text-white transition-all duration-300"
                  >
                    Sign up
                  </Link>
                </div>
              ) : (
                <div className="flex gap-2">
                  <Link
                    to={`${role}/dashboard`}
                    className="p-2 lg:px-4 text-center border-2 border-white text-white text-lg font-medium rounded-full flex justify-center items-center w-32 h-11 hover:bg-primary hover:text-white transition-all duration-300"
                  >
                    Dashboard
                  </Link>
                </div>
              )}
            </div>
          </PopoverGroup>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-10 bg-[#00000038]" />
          <DialogPanel className="fixed inset-y-0 left-0 z-10 top-0 overflow-y-auto bg-white p-[20px] max-w-[300px] sm:ring-1 sm:ring-gray-900/10 h-full">
            <div className="flex items-center justify-end">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="pb-[20px]">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:bg-primary hover:text-white">
                      <Link to="/"> Home</Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-primary hover:text-white">
                      <Link to="/aboutUs">About us</Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-primary hover:text-white">
                      <Link to="/course"> Courses</Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-primary hover:text-white">
                      <Link to="/contactUs">Contact us</Link>
                    </li>
                  </ul>
                  <div className="flex gap-[22px] lg:flex-row flex-col">
                    <div className="w-full sm:max-w-[242px]">
                      <div className="relative flex items-center bg-white1">
                        <BiSearch className="absolute w-5 h-5 top-2.5 left-2.5 text-slate-600" />
                        <input
                          className="w-full placeholder:text-lightgray text-black text-[16px] font-medium bg-white1 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 shadow-sm focus:shadow bg-transparent"
                          placeholder="Search for teachers"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                        />
                      </div>
                    </div>
                    {!isAuthenticated() ? (
                      <div className="flex gap-[8px]">
                        <Link
                          to="/login"
                          className="p-2 lg:px-4 text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                        >
                          Log in
                        </Link>
                        <Link
                          to="/signup"
                          className="p-2 lg:px-4 text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                        >
                          Sign up
                        </Link>
                      </div>
                    ) : (
                      <div className="flex gap-[8px]">
                        <Link
                          to={`${role}/dashboard`}
                          className="p-2 lg:px-4 text-center border-[2px] border-primary text-primary text-[18px] font-medium rounded-full flex justify-center items-center w-[125px] h-[42px] hover:bg-primary hover:text-white"
                        >
                          Dashboard
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </>
  );
}

export default Header;
