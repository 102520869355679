import React from "react";

function ChatUserLoader() {
  return (
    <div>
      {new Array(11).fill(null).map((_, index) => (
        <div
          key={index}
          role="status"
          className="animate-pulse rtl:space-x-reverse flex items-center p-[10px_18px] gap-5"
        >
          <div className="w-full max-w-[50px] h-[50px] bg-[#ccc] rounded-full "></div>
          <div className="w-full">
            <div className="h-2 bg-[#ccc] rounded-full max-w-[150px] mb-[10px]"></div>
            <div className="h-2 bg-[#ccc] rounded-full max-w-[100px]"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ))}
    </div>
  );
}

export default ChatUserLoader;
