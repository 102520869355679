import { createSlice } from '@reduxjs/toolkit';

const initialStudentState = {
    firstName: "",
    lastName: "",
    email: "",
    source: "",
    contactNumber: "",
    interest: "",
    areaOfInterest: [],
    availability: "",
    duration: "",
    startTime: "",
    endTime: "",
    profilePicture: {},
    profileImageUrl: "",
    isProfileSet: false,
};

const studentProfileSlice = createSlice({
    name: 'studentProfile',
    initialState: initialStudentState,
    reducers: {
        updateStudentProfile: (state, action) => {
            return { ...state, ...action.payload, isProfileSet: true, };
        },
        clearStudentProfile: () => initialStudentState,
    },
});

export const { updateStudentProfile, updateStudentSkillMatrix, clearStudentProfile } = studentProfileSlice.actions;
export default studentProfileSlice.reducer;
