import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTeacherList } from "../services/apis/authentication";
import { sendRequest } from "../services/apis/connectionApis";

// Async thunk to fetch teacher list
export const fetchTeacherList = createAsyncThunk(
    "teachers/fetchTeacherList",
    async (
        { page = 1, limit = 12, searchTerm, skillLevel, subcategory, availability, studentId },
        { rejectWithValue }
    ) => {
        try {
            const queryParams = new URLSearchParams();

            queryParams.append("page", page);
            queryParams.append("limit", limit);

            if (searchTerm && searchTerm.trim() !== "") {
                queryParams.append("customFilters", searchTerm);
            }

            if (subcategory && subcategory.length > 0) {
                queryParams.append("subcategory", subcategory.join(","));
            }

            if (skillLevel && skillLevel.length > 0) {
                queryParams.append("skillLevel", skillLevel.join(","));
            }

            if (availability && availability.length > 0) {
                queryParams.append("availability", availability.join(","));
            }

            if (studentId) {
                queryParams.append("studentId", studentId);
            }

            const response = await getTeacherList({
                query: queryParams.toString(),
            });

            return {
                ...response,
                currentPage: page,
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Async thunk to send connection request
export const sendConnection = createAsyncThunk(
    "teachers/sendConnection",
    async (teacherId, { rejectWithValue }) => {
        try {
            const response = await sendRequest({ teacherId });
            return { ...response, teacherId, status: "pending" };
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const teacherSlice = createSlice({
    name: "teachers",
    initialState: {
        teacherList: [],
        loading: true,
        error: null,
        pagination: {
            totalPages: 1,
            currentPage: 1,
            totalUsers: 0,
        },
    },
    reducers: {
        resetTeacherState: () => ({
            teacherList: [],
            loading: false,
            error: null,
            pagination: {
                totalPages: 1,
                currentPage: 1,
                totalUsers: 0,
            },
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeacherList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTeacherList.fulfilled, (state, action) => {
                state.loading = false;
                state.teacherList = action.payload.data;
                state.pagination = {
                    totalPages: action.payload.pagination.totalPages,
                    currentPage: action.payload.currentPage,
                    totalUsers: action.payload.pagination.totalUsers,
                };
            })
            .addCase(fetchTeacherList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.teacherList = [];
                state.pagination = {
                    totalPages: 1,
                    currentPage: 1,
                    totalUsers: 0,
                };
            })
            .addCase(sendConnection.fulfilled, (state, action) => {
                const { teacherId, status } = action.payload;
                const teacher = state.teacherList.find((t) => t._id === teacherId);
                if (teacher) {
                    teacher.connectionStatus = status;
                }
            })
            .addCase(sendConnection.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export const { resetTeacherState } = teacherSlice.actions;

export default teacherSlice.reducer;

