import React from "react";
import CommonDialog from "../components/common/CommonDialog";
import { AiOutlineClose } from "react-icons/ai";
import { responseRequest } from "../services/apis/connectionApis";
import { FaRegHeart } from "react-icons/fa";
import AVATAR from "../assets/Images/no_user.png";
import { Tooltip } from "react-tooltip";

function ConnectionModel({ open, setOpen, pendingRequests, fetchRequests }) {
  const handleRequestResponse = async (connectionId, status) => {
    await responseRequest({ connectionId, status });
    fetchRequests();
  };

  return (
    <>
      <div className="relative cursor-pointer">
        <FaRegHeart
          data-tooltip-id="pendingRequest"
          className="w-[24px] h-[24px]"
        />
        {pendingRequests.length > 0 && (
          <span className="absolute top-[-3px] right-[-3px] text-[7px] flex items-center justify-center w-[12px] h-[12px] font-medium text-white bg-red rounded-full">
            {pendingRequests.length}
          </span>
        )}
        <Tooltip id="pendingRequest" content="Pending Requests" />
      </div>

      <CommonDialog open={open} setOpen={setOpen}>
        <div className="flex items-center justify-between p-4 border-b rounded-t">
          <h3 className="text-xl font-semibold text-gray-900">All Requests</h3>
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
          >
            <AiOutlineClose className="w-5 h-5" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="overflow-y-auto max-h-[310px] sm:max-h-[320px] my-scroll px-2 sm:px-4">
          {pendingRequests.length > 0 ? (
            pendingRequests.map((request) => (
              <div
                key={request._id}
                className="flex flex-col sm:flex-row justify-between gap-2 capitalize border-b border-b-gray-300 py-3"
              >
                <div className="flex items-center">
                  <img
                    src={request.sender.profileImageUrl || "defaultImage.png"}
                    alt={`${request.sender.firstName} ${request.sender.lastName}`}
                    className="w-[34px] sm:w-[40px] rounded-full"
                    onError={(e) => {
                      e.target.src = AVATAR;
                    }}
                  />
                  <div className="ml-2 sm:ml-4 sm:text-lg text-ellipsis overflow-hidden whitespace-nowrap">
                    {request.sender.firstName} {request.sender.lastName} sent a
                    connection request.
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <button
                    onClick={() =>
                      handleRequestResponse(request._id, "accepted")
                    }
                    className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-100 font-medium rounded-lg text-sm px-3 sm:px-4 py-2"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() =>
                      handleRequestResponse(request._id, "rejected")
                    }
                    className="py-2 px-3 sm:px-4 ml-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary focus:ring-4 focus:ring-gray-100"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center p-5">No pending requests</p>
          )}
        </div>
      </CommonDialog>
    </>
  );
}

export default ConnectionModel;
