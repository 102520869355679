// OnlineUserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const OnlineUserContext = createContext();

export const useOnlineUsers = () => {
  return useContext(OnlineUserContext);
};

export const OnlineUserProvider = ({ children }) => {
  const [onlineUsers, setOnlineUsers] = useState(new Map());
  const [chatHistory, setChatHistory] = useState([]);
  const [receiver, setReceiver] = useState(null);

  return (
    <OnlineUserContext.Provider
      value={{
        onlineUsers,
        setOnlineUsers,
        setChatHistory,
        chatHistory,
        receiver,
        setReceiver,
      }}
    >
      {children}
    </OnlineUserContext.Provider>
  );
};
