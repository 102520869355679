import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import 'firebase/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD3RCPlKWZX-TR6X2QO5oRPuxZk3U7fQvE",
    authDomain: "siqsha-af94b.firebaseapp.com",
    projectId: "siqsha-af94b",
    storageBucket: "siqsha-af94b.firebasestorage.app",
    messagingSenderId: "235433138603",
    appId: "1:235433138603:web:dc57c7d76e04f8a75e1aee",
    measurementId: "G-2MTTFHR3B1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const messaging = getMessaging(app);

// export const generateFirebaseToken = async () => {
//     try {
//         // Debugging notification permissions
//         // if (Notification.permission === "default") {
//         //     const permission = await Notification.requestPermission();
//         //     if (permission !== "granted") {
//         //         console.error("Notification permission not granted");
//         //         return;
//         //     }
//         // }

//         const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIKEY });
//         if (token) {
//             return token;
//         } else {
//             console.error("FCM Token generation failed. Token is undefined.");
//         }
//     } catch (error) {
//         console.error("Failed to get notification token", error);
//     }
// };

// Listen for incoming messages
// onMessage(messaging, (payload) => {
//     alert(payload.notification.title);
// });

// export { auth, app, messaging };
export { auth, app };
