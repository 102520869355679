import React from "react";
import Sidebar from "../profile/Sidebar";
import UserHeader from "../header/UserHeader";
import { isAuthenticated } from "../../pages/router/ProtectedRoute";
import AuthHeader from "../header/AuthHeader";
import Header from "../Header";

const CommonLayout = ({ title, children }) => {
  const authenticated = isAuthenticated();
  return (
    <>
      {authenticated ? (
        <div className="!flex bg-gray1 justify-end">
          <Sidebar />
          <div className="xl:!w-[calc(100%-301px)] w-full">
            {authenticated ? <UserHeader /> : <Header />}
            <div className="md:p-[14px_26px] sm:p-[25px_35px] p-[16px] h-[calc(100%-280px)]">
              {title && (
                <h1 className="text-[30px] font-medium text-nevyblue mb-[5px]">
                  {title}
                </h1>
              )}
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {authenticated ? <UserHeader /> : <Header />}
          <div>{children}</div>
        </div>
      )}
    </>
  );
};

export default CommonLayout;
